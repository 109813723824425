
export const getTranslation = (key: string, params: {[key: string]: string} = {}) => {
  const translation: {[key: string]: string} = {
    // Profile
    'meta.profile.profile-details.title': $localize`:@@meta.profile.profile-details.title:Profile | ${params.username}:username:  | Kozikaza` as string as string,
    'meta.profile.profile-kazamis.title': $localize`:@@meta.profile.profile-kazamis.title:Kazamis | ${params.username}:username:  | Kozikaza` as string,
    'meta.profile.profile-info.title': $localize`:@@meta.profile.profile-info.title:Account Information | ${params.username}:username:  | Kozikaza` as string,
    'meta.profile.profile-settings.title': $localize`:@@meta.profile.profile-settings.title:Notification settings | ${params.username}:username:  | Kozikaza` as string,
    'meta.profile.profile-kaza.title': $localize`:@@meta.profile.profile-kaza.title:Kazas | ${params.username}:username: | Kozikaza` as string,
    'meta.profile.profile-notifications-list.title': $localize`:@@meta.profile.profile-notifications-list.title:Notifications | ${params.username}:username:  | Kozikaza` as string,
    'meta.profile.profile-messagerie.title': $localize`:@@meta.profile.profile-messagerie.title:Messages | ${params.username}:username:  | Kozikaza` as string,
    'meta.profile.profile-coaching.title': $localize`:@@meta.profile.profile-coaching.title:Coaching | ${params.username}:username:  | Kozikaza` as string,
    // Breadcrumb
    'kz-breadcrumb.inspiration.home': $localize`:@@kz-breadcrumb.inspiration.home:Home` as string,
    'kz-breadcrumb.inspiration.bathroom': $localize`:@@kz-breadcrumb.inspiration.bathroom:Bathroom` as string,
    'kz-breadcrumb.inspiration.bathroom-photos': $localize`:@@kz-breadcrumb.inspiration.bathroom-photos:Bathroom photos` as string,
    'kz-breadcrumb.inspiration.bathroom-photos-hd': $localize`:@@kz-breadcrumb.inspiration.bathroom-photos-hd:Bathroom Ideas` as string,
    'kz-breadcrumb.inspiration.bathroom-plans': $localize`:@@kz-breadcrumb.inspiration.bathroom-plans:3D bathroom plans models` as string,
    'kz-breadcrumb.inspiration.kitchen': $localize`:@@kz-breadcrumb.inspiration.kitchen:Kitchen` as string,
    'kz-breadcrumb.inspiration.kitchen-photos': $localize`:@@kz-breadcrumb.inspiration.kitchen-photos:Kitchen photos` as string,
    'kz-breadcrumb.inspiration.kitchen-photos-hd': $localize`:@@kz-breadcrumb.inspiration.kitchen-photos-hd:Kitchen ideas` as string,
    'kz-breadcrumb.inspiration.kitchen-plans': $localize`:@@kz-breadcrumb.inspiration.kitchen-plans:3D kitchen plans models` as string,
    'kz-breadcrumb.inspiration.dressing': $localize`:@@kz-breadcrumb.inspiration.dressing:Wardrobe and storage` as string,
    'kz-breadcrumb.inspiration.dressing-photos': $localize`:@@kz-breadcrumb.inspiration.dressing-photos:Wardrobe and storage photos` as string,
    'kz-breadcrumb.inspiration.dressing-plans': $localize`:@@kz-breadcrumb.inspiration.dressing-plans:3D Wardrobe and storage plans models` as string,
    'kz-breadcrumb.inspiration.outdoor-design': $localize`:@@kz-breadcrumb.inspiration.outdoor-design:Outdoor design` as string,
    'kz-breadcrumb.inspiration.outdoor-design-terrace-photos': $localize`:@@kz-breadcrumb.inspiration.outdoor-design-terrace-photos:Terrace photos` as string,
    'kz-breadcrumb.inspiration.outdoor-design-garden-photos': $localize`:@@kz-breadcrumb.inspiration.outdoor-design-garden-photos:Garden photos` as string,
    'kz-breadcrumb.plan3d.landing': $localize`:@@kz-breadcrumb.plan3d.landing:3D house plan` as string,
    'kz-breadcrumb.plan3d.bathroom': $localize`:@@kz-breadcrumb.plan3d.bathroom:Bathroom 3D Plan` as string,
    'kz-breadcrumb.plan3d.electricity': $localize`:@@kz-breadcrumb.plan3d.electricity:Electrical plan` as string,
    'kz-breadcrumb.plan3d.kitchen-planner': $localize`:@@kz-breadcrumb.plan3d.kitchen-planner:Kitchen design tool` as string,
    'kz-breadcrumb.plan3d.3d-landscaping-plan': $localize`:@@kz-breadcrumb.plan3d.3d-landscaping-plan:3D landscaping plan` as string,
    'kz-breadcrumb.plan3d.design-by-professional': $localize`:@@kz-breadcrumb.plan3d.design-by-professional:Design and decoration service` as string,
    'kz-breadcrumb.plan3d.pricing': $localize`:@@kz-breadcrumb.plan3d.pricing:Pricing` as string,
    'kz-breadcrumb.contest.landing': $localize`:@@kz-breadcrumb.contest.landing:Contest` as string,
    'kz-breadcrumb.contest.default': $localize`:@@kz-breadcrumb.contest.default:Default` as string,
    'kz-breadcrumb.contest.renovation': $localize`:@@kz-breadcrumb.contest.renovation:Renovation` as string,
    'kz-breadcrumb.contest.construction': $localize`:@@kz-breadcrumb.contest.construction:Construction` as string,
    'kz-breadcrumb.contest.rules': $localize`:@@kz-breadcrumb.contest.rules:Rules` as string,
    'kz-breadcrumb.pro.listing': $localize`:@@kz-breadcrumb.pro.listing:Pro` as string,
    'kz-breadcrumb.pro.listing-box': $localize`:@@kz-breadcrumb.pro.listingBox:Box` as string,
    'kz-breadcrumb.pro.listing-style': $localize`:@@kz-breadcrumb.pro.listing-style:Style ${params.styleName}:styleName:` as string,
    'kz-breadcrumb.pro.listing-city': $localize`:@@kz-breadcrumb.pro.listing-city:${params.cityName}:cityName:` as string,
    'kz-breadcrumb.pro.listingPage': $localize`:@@kz-breadcrumb.pro.listingPage:Page ${params.page}:page:` as string,
    'kz-breadcrumb.pro.landing-decoration': $localize`:@@kz-breadcrumb.pro.landing-decoration:Interior decorators` as string,
    'kz-breadcrumb.pro.listing-interior-decorator': $localize`:@@kz-breadcrumb.pro.listing-interior-decorator:Interior decorators` as string,
    'kz-breadcrumb.pro.listing-interior-decorator-style': $localize`:@@kz-breadcrumb.pro.listing-interior-decorator-style:Style ${params.styleName}:styleName:` as string,
    'kz-breadcrumb.pro.listing-interior-architect': $localize`:@@kz-breadcrumb.pro.listing-interior-architect:Interior architects` as string,
    'kz-breadcrumb.pro.listing-interior-architect-style': $localize`:@@kz-breadcrumb.pro.listing-interior-architect-style:Style ${params.styleName}:styleName:` as string,
    'kz-breadcrumb.pro-coach': $localize`:@@kz-breadcrumb.pro-coach:Decoration coaching` as string,
    'kz-breadcrumb.pro.boxActivation': $localize`:@@kz-breadcrumb.pro.boxActivation:Gift voucher activation` as string,
    'kz-breadcrumb.boxCoachingDeco': $localize`:@@kz-breadcrumb.boxCoachingDeco:Decoration coaching box` as string,
    'kz-breadcrumb.inspiration.outdoor-design-3d-plan': $localize`:@@kz-breadcrumb.inspiration.outdoor-design-3d-plan:Garden and terrace plan templates` as string,
    
    // Kaza
    'meta.kaza.posts-flux.title': $localize`:@@meta.kaza.posts-flux.title:Story | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.img-gallery.kaza.title': $localize`:@@meta.img-gallery.kaza.title:Pictures | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.img-gallery.kaza.titleIdea': $localize`:@@meta.img-gallery.kaza.titleIdea:Ideas | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.kaza.kaza-plan3d.title': $localize`:@@meta.kaza.kaza-plan3d.title:3D Plans | ${params.kazaName}:kazaName:  | Kozikaza` as string,
    'meta.kaza.favorites.title': $localize`:@@meta.kaza.favorites.title:Favorites | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.kaza.kazabook.title': $localize`:@@meta.kaza.kazabook.title:Kazabook | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.kaza.view.title': $localize`:@@meta.kaza.view.title:Information | ${params.kazaName}:kazaName: | Kozikaza` as string,
    'meta.kaza.memories.title': $localize`:@@meta.kaza.memories.title:Story | ${params.kazaName}:kazaName: | Kozikaza` as string,
    // Countries
    'countries.FR': $localize`:@@countries.FR:France` as string,
    'countries.BE': $localize`:@@countries.BE:Belgium` as string,
    'countries.CH': $localize`:@@countries.CH:Switzerland` as string,
    'countries.YT': $localize`:@@countries.YT:Mayotte` as string,
    'countries.G': $localize`:@@countries.GF:French Guiana` as string,
    'countries.GP': $localize`:@@countries.GP:Guadeloupe` as string,
    'countries.RE': $localize`:@@countries.RE:Réunion` as string,
    'countries.MQ': $localize`:@@countries.MQ:Martinique` as string,
    // Languages
    'languages.fr': $localize`:@@languages.fr:French` as string,
    'languages.en': $localize`:@@languages.en:English` as string,
    'languages.pl': $localize`:@@languages.pl:Polish` as string,
    'languages.es': $localize`:@@languages.es:Spanish` as string,
    // Toast
    'toast.favorite_post_added': $localize`:@@toast.favorite_post_added:The post have been added to favorites` as string,
    'toast.favorite_picture_added': $localize`:@@toast.favorite_picture_added:The picture have been added to favorites` as string,
    'toast.favorite_plan_added': $localize`:@@toast.favorite_plan_added:The plan have been added to favorites` as string,
    'toast.favorite_post_removed': $localize`:@@toast.favorite_post_removed:The post have been removed from favorites` as string,
    'toast.favorite_picture_removed': $localize`:@@toast.favorite_picture_removed:The picture have been removed from favorites` as string,
    'toast.favorite_plan_removed': $localize`:@@toast.favorite_plan_removed:The plan have been removed from favorites` as string,
    // POST
    'post.postTypes.pictures': $localize`:@@post.postTypes.pictures:Pictures` as string,
    'post.postTypes.before-after': $localize`:@@post.postTypes.before-after:Before/After` as string,
    'post.postTypes.event': $localize`:@@post.postTypes.event:Event` as string,
    'post.postTypes.survey': $localize`:@@post.postTypes.survey:Poll` as string,
    'post.postTypes.question': $localize`:@@post.postTypes.question:Question` as string,
    'post.postTypes.idea': $localize`:@@post.postTypes.idea:Idea` as string,
    'post.postTypes.video': $localize`:@@post.postTypes.video:Video` as string,
    // Inspiration
    'meta.inspiration.photoCatSlug.bathroom': $localize`:@@meta.inspiration.photoCatSlug.bathroom:bathroom` as string,
    'meta.inspiration.photoCatSlug.kitchen': $localize`:@@meta.inspiration.photoCatSlug.kitchen:kitchen` as string,
    'meta.inspiration.mediaTypes.photo': $localize`:@@meta.inspiration.mediaTypes.photo:photo` as string,
    'meta.inspiration.mediaTypes.ide': $localize`:@@meta.inspiration.mediaTypes.idea:idea` as string,
    'meta.inspiration.mediaTypes.plan': $localize`:@@meta.inspiration.mediaTypes.plan:plan` as string,
    'meta.inspiration.mediaTypes.photos': $localize`:@@meta.inspiration.mediaTypes.photos:photos` as string,
    'meta.inspiration.mediaTypes.ideas': $localize`:@@meta.inspiration.mediaTypes.ideas:ideas` as string,
    'meta.inspiration.mediaTypes.plans': $localize`:@@meta.inspiration.mediaTypes.plans:plans` as string,
    // Contest
    'contest.type.construction': $localize`:@@contest.type.construction:construction` as string,
    'contest.type.renovation': $localize`:@@contest.type.renovation:renovation` as string,
    // Notification
    'notification.item.answer-him': $localize`:@@notification.item.answer-him:Lui Répondre` as string,
    'notification.item.and': $localize`:@@notification.item.and:and` as string,
    'notification.item.message': $localize`:@@notification.item.message:message` as string,
    'notification.item.question': $localize`:@@notification.item.question:a question` as string,
    'notification.item.survey': $localize`:@@notification.item.survey:a survey` as string,
    'notification.item.poll': $localize`:@@notification.item.poll:poll` as string,
    'notification.item.post': $localize`:@@notification.item.post:post` as string,
    // PRO
    'meta.pro.edit.title': $localize`:@@meta.pro.edit.title:${params.proName}:proName:, ${params.jobName}:jobName: at ${params.city}:city: | Edition |  Kozikaza` as string,
    'meta.pro.edit.titleWithoutCity': $localize`:@@meta.pro.edit.titleWithoutCity:${params.proName}:proName:, ${params.jobName}:jobName: | Edition |  Kozikaza` as string,
    'meta.pro.posts-flux.title': $localize`:@@meta.pro.posts-flux.title:${params.proName}:proName:, ${params.jobName}:jobName: at ${params.city}:city: | Kozikaza` as string,
    'meta.pro.posts-flux.titleWithoutCity': $localize`:@@meta.pro.posts-flux.titleWithoutCity:${params.proName}:proName:, ${params.jobName}:jobName: | Kozikaza` as string,
    'meta.pro.activity.title': $localize`:@@meta.pro.activity.title:${params.proName}:proName:, ${params.jobName}:jobName: at ${params.city}:city: | Activity |  Kozikaza` as string,
    'meta.pro.activity.titleWithoutCity': $localize`:@@meta.pro.activity.titleWithoutCity:${params.proName}:proName:, ${params.jobName}:jobName: | Activity |  Kozikaza` as string,
    'meta.pro.service.title': $localize`:@@meta.pro.service.title:${params.proName}:proName:, ${params.jobName}:jobName: at ${params.city}:city: | Services |  Kozikaza` as string,
    'meta.pro.service.titleWithoutCity': $localize`:@@meta.pro.service.titleWithoutCity:${params.proName}, ${params.jobName} | Services |  Kozikaza` as string,
    'meta.pro.listing.title': $localize`:@@meta.pro.listing.title:Best decoration professionals in France | Kozikaza` as string,
    'meta.pro.listing.description': $localize`:@@meta.pro.listing.description:Discover the best decorating professionals to fit out your home, decorate your house or follow up your project with online or on-site assistance. Best decoration professionals in France!` as string,

    'meta.pro.listing-city.title': $localize`:@@meta.pro.listing-city.title:Best decoration professionals at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-city.description': $localize`:@@meta.pro.listing-city.description:Discover the best decorating professionals at ${params.cityName}:cityName: to fit out your home, decorate your house or follow up your project with online or on-site assistance. Best decoration professionals in France!` as string,

    'meta.pro.listing-interior-decorator-city.title': $localize`:@@meta.pro.listing-interior-decorator-city.title:Best interior decorators at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-interior-architect-city.title': $localize`:@@meta.pro.listing-interior-architect-city.title:Best interior architects at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-interior-decorator-city.description': $localize`:@@meta.pro.listing-interior-decorator-city.description:Discover the best interior decorators at ${params.cityName}:cityName: to fit out your home, decorate your house or follow up your project with online or on-site assistance. Best decoration professionals at ${params.cityName}:cityName:!` as string,
    'meta.pro.listing-interior-architect-city.description': $localize`:@@meta.pro.listing-interior-architect-city.description:Discover the best interior architects at ${params.cityName}:cityName: to fit out your home, decorate your house or follow up your project with online or on-site assistance. Best decoration professionals at ${params.cityName}:cityName:!` as string,
    'meta.pro.listing-style.title': $localize`:@@meta.pro.listing-style.title:Best decoration professionals with style ${params.styleName}:styleName: in France | Kozikaza` as string,
    'meta.pro.listing-style.description': $localize`:@@meta.pro.listing-style.description:Discover the best decorating professionals with style ${params.styleName}:styleName: to furnish and decorate your home or to follow up on your project with online or on-site assistance. Best decorating professionals in France!` as string,
    'meta.pro.listing-city-style.title': $localize`:@@meta.pro.listing-city-style.title:Best decoration professionals with style ${params.styleName}:styleName: at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-city-style.description': $localize`:@@meta.pro.listing-city-style.description:Discover the best decorating professionals with style ${params.styleName}:styleName: at ${params.cityName}:cityName: to furnish and decorate your home or to follow up on your project with online or on-site assistance. Best decorating professionals at ${params.cityName}:cityName:!` as string,
    'meta.pro.listing-interior-decorator.title': $localize`:@@meta.pro.listing-interior-decorator.title:Best interior designers in France | Kozikaza` as string,
    'meta.pro.listing-interior-decorator.description': $localize`:@@meta.pro.listing-interior-decorator.description:Discover the best interior designers to decorate your home with coaching and support online or on site. Best decorators in France!` as string,
    'meta.pro.listing-interior-decorator-style.title': $localize`:@@meta.pro.listing-interior-decorator-style.title:Best interior designers with style ${params.styleName}:styleName: in France | Kozikaza` as string,
    'meta.pro.listing-interior-decorator-style.description': $localize`:@@meta.pro.listing-interior-decorator-style.description:Discover the best interior designers with style ${params.styleName}:styleName: to decorate your home with coaching and support online or on site. Best decorators in France!` as string,

    'meta.pro.listing-interior-decorator-city-style.title': $localize`:@@meta.pro.listing-interior-decorator-city-style.title:Best interior designers with style ${params.styleName}:styleName: at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-interior-decorator-city-style.description': $localize`:@@meta.pro.listing-interior-decorator-city-style.description:Discover the best interior designers with style ${params.styleName}:styleName: at ${params.cityName}:cityName: to decorate your home with coaching and support online or on site. Best decorators at ${params.cityName}:cityName:!` as string,

    'meta.pro.listing-interior-architect.title': $localize`:@@meta.pro.listing-interior-architect.title:Best interior architects in France | Kozikaza` as string,
    'meta.pro.listing-interior-architect.description': $localize`:@@meta.pro.listing-interior-architect.description:Discover the best interior architects for space planning, site supervision and decoration. Best interior architects in France!` as string,
    'meta.pro.listing-interior-architect-style.title': $localize`:@@meta.pro.listing-interior-architect-style.title:Best interior architects with style ${params.styleName}:styleName: in France | Kozikaza` as string,
    'meta.pro.listing-interior-architect-style.description': $localize`:@@meta.pro.listing-interior-architect-style.description:Discover the best interior architects with style ${params.styleName}:styleName: for space planning, site supervision and decoration. Best interior designers in France!` as string,

    'meta.pro.listing-interior-architect-city-style.title': $localize`:@@meta.pro.listing-interior-architect-city-style.title:Best interior architects with style ${params.styleName}:styleName: at ${params.cityName}:cityName: | Kozikaza` as string,
    'meta.pro.listing-interior-architect-city-style.description': $localize`:@@meta.pro.listing-interior-architect-city-style.description:Discover the best interior architects with style ${params.styleName}:styleName: at ${params.cityName}:cityName: for space planning, site supervision and decoration. Best interior designers at ${params.cityName}:cityName:!` as string,

    'meta.pro.listing-box.title': $localize`:@@meta.pro.listingBox.title:Decorators participating in the Coaching decoration box` as string,
    'meta.pro.listing-box.description': $localize`:@@meta.pro.listingBox.description:Meet our interior decorators available throughout France and take advantage of your gift voucher included in the "decoration coaching" box` as string,
    'meta.pro.listing-box-style.title': $localize`:@@meta.pro.listingBox-style.title:Decorators with style ${params.styleName}:styleName: participating in the Coaching decoration box | Kozikaza` as string,
    'meta.pro.listing-box-style.description': $localize`:@@meta.pro.listingBox-style.description:Meet our interior decorators with style ${params.styleName}:styleName: available throughout France and take advantage of your gift voucher included in the "decoration coaching" box` as string,
    'meta.pro.job.title': $localize`:@@meta.pro.job.title:Listing | ${params.jobName}:jobName:  | Kozikaza` as string,
    'meta.pro.plan3d.title': $localize`:@@meta.pro.plan3d.title:${params.proName}:proName:, ${params.jobName}:jobName: at ${params.city}:city: | 3D Plans |  Kozikaza` as string,
    'meta.pro.plan3d.titleWithoutCity': $localize`:@@meta.pro.plan3d.titleWithoutCity:${params.proName}:proName:, ${params.jobName}:jobName: | 3D Plans |  Kozikaza` as string,
    'meta.pro.boxActivation.title': $localize`:@@meta.pro.boxActivation.title:Gift voucher activation |  Kozikaza` as string,
    'meta.pro.boxActivation.description': $localize`:@@meta.pro.boxActivation.description:Gift voucher activation |  Kozikaza` as string,
    'meta.pro.boxCoachingDeco2.title': $localize`:@@meta.pro.boxCoachingDeco.title:The decoration coaching box - the gift box for all occasions |  Kozikaza` as string,
    'meta.pro.boxCoachingDeco2.description': $localize`:@@meta.pro.boxCoachingDeco.description:buying a house, renovating, housewarming... help your loved ones realise their decorating dreams with the advice of an interior decorator.` as string,
    // KAZACAFE
    'meta.kazacafe.kazacafe-explore.title': $localize`:@@meta.kazacafe.kazacafe-explore.title:Kazacafé Explore | Kozikaza` as string,
    'meta.kazacafe.kazacafe-explore.description': $localize`:@@meta.kazacafe.kazacafe-explore.description:Explore Kazacafé, the Kozikaza community exchange and find projects near you or similar to yours as string, draw ideas ...` as string,
    'meta.kazacafe.kazacafe-discover.title': $localize`:@@meta.kazacafe.kazacafe-discover.title:Kazacafe, Stories Works House: Construction, Renovation | Kozikaza` as string,
    'meta.kazacafe.kazacafe-discover.description': $localize`:@@meta.kazacafe.kazacafe-discover.description:Discover the Kazacafé, the exchange area of the Kozikaza community. You will find the latest achievements of members as string, their ideas as string, their questions ...` as string,
    'meta.kazacafe.kazacafe-follow-suggest.title': $localize`:@@meta.kazacafe.kazacafe-follow-suggest.title:Suggestion of follow-ups | {${params.userName}:userName: | Kozikaza` as string,
    'meta.kazacafe.kazacafe-follow-flux.title': $localize`:@@meta.kazacafe.kazacafe-follow-flux.title:News of follow-ups | Kozikaza` as string,
    'meta.kazacafe.kazacafe-follow-flux.description': $localize`:@@meta.kazacafe.kazacafe-follow-flux.description:Kozikaza members' newsfeed that you follow` as string,
    'meta.kazacafe.kazacafe-follow-followers.title': $localize`:@@meta.kazacafe.kazacafe-follow-followers.title:'They follow me | ${params.userName}:userName:' | Kozikaza'` as string,
    'meta.kazacafe.kazacafe-follow-followed.title': $localize`:@@meta.kazacafe.kazacafe-follow-followed.title:I follow them | ${params.userName}:userName: | Kozikaza` as string,
    // KAZABOOK
    'meta.kazabook.kazabook-details.title': $localize`:@@meta.kazabook.kazabook-details.title:'${params.kazaName}:kazaName:''s Kazabook` as string,
    // Landing
    'meta.landing.homepage.title': $localize`:@@meta.landing.homepage.title:Community and tools for the home | Kozikaza` as string,
    'meta.landing.homepage.description': $localize`:@@meta.landing.homepage.description:Create your virtual house with the 3D plan, interact with other members, ask for advice to the Kozikaza community` as string,
    'meta.landing.plan-3d.title': $localize`:@@meta.landing.plan-3d.title:Free 3D Home Design Software - Floor Plan Creator` as string,
    'meta.landing.plan-3d.description': $localize`:@@meta.landing.plan-3d.description:Create your 3D Home Plan with ease with our Kazaplan Interior Design Software to draw, decorate and furnish your home. ✓100% online` as string,
    'meta.landing.plan-3d.electricity.title': $localize`:@@meta.landing.plan-3d.electricity.title:Home Electrical Plan - Free Electric Schematic Software | Kozikaza` as string,
    'meta.landing.plan-3d.electricity.description': $localize`:@@meta.landing.plan-3d.electricity.description:▻▻Create your ELECTRICAL DIAGRAM: Prepare your electrical installation serenely with Kozikaza ✓ Software 100% free and online` as string,
    'meta.landing.plan-3d.bathroom-3d.title': $localize`:@@meta.landing.plan-3d.bathroom-3d.title:3D Bathroom Plan - Free Online Software | Kozikaza` as string,
    'meta.landing.plan-3d.bathroom-3d.description': $localize`:@@meta.landing.plan-3d.bathroom-3d.description:▻▻Create your 3D BATHROOM PLAN with our free software: design, layout and decoration of your future bathroom ✓100% free and online` as string,
    'meta.landing.plan-3d.kitchen-planner.title': $localize`:@@meta.landing.plan-3d.kitchen-planner.title:Kitchen design tool and planner | Kozikaza` as string,
    'meta.landing.plan-3d.kitchen-planner.description': $localize`:@@meta.landing.plan-3d.kitchen-planner.description:▻▻Design your kitchen online with our free home design planner software` as string,
    'meta.landing.plan-3d.design-by-professional.title': $localize`:@@meta.landing.plan-3d.design-by-professional.title:A professional designs and draws your interior in 3D | Kozikaza` as string,
    'meta.landing.plan-3d.design-by-professional.description': $localize`:@@meta.landing.plan-3d.design-by-professional.description:Our interior designers offer you the design of your 3D plan, the layout, and the decoration of your room. Kitchen, bathroom, living room, dining room, project yourself in your future room with quality renderings.` as string,
    'meta.landing.plan-3d.3d-landscaping-plan.title': $localize`:@@meta.landing.plan-3d.3d-landscaping-plan.title:3D landscaping plan | Kozikaza` as string,
    'meta.landing.plan-3d.3d-landscaping-plan.description': $localize`:@@meta.landing.plan-3d.3d-landscaping-plan.description:▻▻Design your ideal exterior easily with our free online tool` as string,
    'meta.landing.plan-3d.pricing.title': $localize`:@@meta.landing.plan-3d.pricing.title:Pricing | Kozikaza` as string,
    'meta.landing.plan-3d.pricing.description': $localize`:@@meta.landing.plan-3d.pricing.description:Create your 3D Home Plan with ease with our Kazaplan Interior Design Software to draw, decorate and furnish your home. ✓100% online` as string,

    'meta.landing.cgv.title': $localize`:@@meta.landing.cgv.title:Terms of Sales | Kozikaza` as string,
    'meta.landing.cgu.title': $localize`:@@meta.landing.cgu.title:Terms of Service | Kozikaza` as string,
    'meta.landing.about.title': $localize`:@@meta.landing.about.title:Team | Kozikaza` as string,
    'meta.landing.contest.title': $localize`:@@meta.landing.contest.title:Contest - Kozikaza` as string,
    'meta.landing.contest.description': $localize`:@@meta.landing.contest.description:Each month, a jury of Kozikaza members selects their favorite project. Try your luck !` as string,
    'meta.landing.contest.rules.title': $localize`:@@meta.landing.contest.rules.title:Contest rules - Kozikaza` as string,
    'meta.landing.contest.rules.description': $localize`:@@meta.landing.contest.rules.description:Each month, a jury of Kozikaza members selects their favorite project. Try your luck!` as string,
    'meta.landing.legal-notice.title': $localize`:@@meta.landing.legal-notice.title:Legal Notices | Kozikaza` as string,
    'meta.landing.pro-coach.title': $localize`:@@meta.landing.pro-coach.title:Find an interior designer for your home / Kozikaza` as string,
    'meta.landing.pro-coach.description': $localize`:@@meta.landing.pro-coach.description:Online interior design plateform, online or in-home coaching, choose the interior designer you want to work with.` as string,
    'meta.pro.landing-decoration.title': $localize`:@@meta.pro.landing-decoration.title:Find new customers and have new tools: 3D plan, photo-realistic rendering.` as string,
    'meta.pro.landing-decoration.description': $localize`:@@meta.pro.landing-decoration.description:Discover on Kozikaza a platform dedicated to decoration professionals. Increase your visibility, find new customers, take advantage of our modeling tools, 3D plan, HD rendering.` as string,
    'meta.inspiration.bathroom.title': $localize`:@@meta.inspiration.bathroom.title:Bathroom - Inspiration, Photos and decorating ideas ${params.year}:year: | Kozikaza` as string,
    'meta.inspiration.bathroom.description': $localize`:@@meta.inspiration.bathroom.description:▻▻Get inspired for your bathroom: tips, 3D plans from our architects, bathroom photos, 3D bathroom ideas ... Let yourself be dreamed!` as string,
    'meta.inspiration.bathroom.photo-gallery.title': $localize`:@@meta.inspiration.bathroom.photo-gallery.title:Photos Bathroom - Inspiration Deco and Layout | Kozikaza` as string,
    'meta.inspiration.bathroom.photo-gallery.description': $localize`:@@meta.inspiration.bathroom.photo-gallery.description:▻▻Find many bathroom photos from real projects. Italian bathroom, modern bathroom or attic picture ... Inspire yourself !` as string,
    'meta.inspiration.bathroom.hd-gallery.title': $localize`:@@meta.inspiration.bathroom.hd-gallery.title:Bathroom Ideas - Inspiration Deco and Layout | Kozikaza` as string,
    'meta.inspiration.bathroom.hd-gallery.description': $localize`:@@meta.inspiration.bathroom.hd-gallery.description:▻▻Find many 3D bathroom ideas from our architects! Small bathroom, Italian style, decoration, tiling, color ... Inspire yourself !` as string,
    'meta.inspiration.bathroom.plan-gallery.title': $localize`:@@meta.inspiration.bathroom.plan-gallery.title:3D Bathroom Plans - Examples of configurations | Kozikaza` as string,
    'meta.inspiration.bathroom.plan-gallery.description': $localize`:@@meta.inspiration.bathroom.plan-gallery.description:▻▻Find many 3D bathroom ideas from our architects. Small bathroom, Italian style, decoration, tiling, color ... Inspire yourself !` as string,
    'meta.inspiration.kitchen.title': $localize`:@@meta.inspiration.kitchen.title:Kitchen - Inspiration, Photos and decoration ideas  ${params.year}:year: | Kozikaza` as string,
    'meta.inspiration.kitchen.description': $localize`:@@meta.inspiration.kitchen.description:▻▻Get inspired for your kitchen : tips, 3D plans from our architects, kitchen photos, 3D kitchen ideas ... Let yourself be dreamed!` as string,
    'meta.inspiration.kitchen.photo-gallery.title': $localize`:@@meta.inspiration.kitchen.photo-gallery.title:Photos kitchen - Inspiration Deco and Layout | Kozikaza` as string,
    'meta.inspiration.kitchen.photo-gallery.description': $localize`:@@meta.inspiration.kitchen.photo-gallery.description:▻▻Find many kitchen photos from real projects. American kitchen, modern or industrial kitchen photos ... Inspire yourself !` as string,
    'meta.inspiration.kitchen.hd-gallery.title': $localize`:@@meta.inspiration.kitchen.hd-gallery.title:Kitchen Ideas - Inspiration Deco and Layout | Kozikaza` as string,
    'meta.inspiration.kitchen.hd-gallery.description': $localize`:@@meta.inspiration.kitchen.hd-gallery.description:▻▻Find many 3D kitchen ideas from our architects! Inspire yourself !` as string,
    'meta.inspiration.kitchen.plan-gallery.title': $localize`:@@meta.inspiration.kitchen.plan-gallery.title:3D Kitchen Plans - Examples from 2m2 to 8m2 | Kozikaza` as string,
    'meta.inspiration.kitchen.plan-gallery.description': $localize`:@@meta.inspiration.kitchen.plan-gallery.description:▻▻Find many 3D kicthen ideas from our architects. Inspire yourself !` as string,
    'meta.inspiration.dressing.title': $localize`:@@meta.inspiration.dressing.title:Wardrobe and storage - Inspiration, Photos and decoration ideas ${params.year}:year: | Kozikaza` as string,
    'meta.inspiration.dressing.description': $localize`:@@meta.inspiration.dressing.description:▻▻ Discover models of 3D plans, photos of Kozikaza members' projects and advice in the Mag. Find inspiration for your wardrobe and storage! ` as string,
    'meta.inspiration.dressing.photo-gallery.title': $localize`:@@meta.inspiration.dressing.photo-gallery.title:Photos and decorative inspirations for dressing room and storage | Kozikaza` as string,
    'meta.inspiration.dressing.photo-gallery.description': $localize`:@@meta.inspiration.dressing.photo-gallery.description:▻▻ Find pictures of dressing rooms and storage from the projects of Kozikaza members. L-shaped, U-shaped, under slope ... Let yourself be inspired!` as string,
    'meta.inspiration.dressing.plan-gallery.title': $localize`:@@meta.inspiration.dressing.plan-gallery.title:3D Dressing room plan models - configuration examples | Kozikaza` as string,
    'meta.inspiration.dressing.plan-gallery.description': $localize`:@@meta.inspiration.dressing.plan-gallery.description:▻▻ Discover our 15 examples of 3D PLANS of 2020 wardrobes designed by our architects: open or closed L-shaped, U-shaped or I-shaped wardrobes, with an industrial or chic look.` as string,
    'meta.inspiration.outdoor-design.title': $localize`:@@meta.inspiration.outdoor-design.title:Outdoor design - Inspiration, Photos and Decorating Ideas ${params.year}:year: | Kozikaza` as string,
    'meta.inspiration.outdoor-design.description': $localize`:@@meta.inspiration.outdoor-design.description:▻▻ Get inspired for your outdoor design: advice, photos, tips... Let yourself be carried away! ` as string,
    'meta.inspiration.outdoor-design.photo-gallery.title': $localize`:@@meta.inspiration.outdoor-design.photo-gallery.title:Photos and decorative inspirations for outdoor design | Kozikaza` as string,
    'meta.inspiration.outdoor-design.photo-gallery.description': $localize`:@@meta.inspiration.outdoor-design.photo-gallery.description:▻▻ Find pictures of outdoor design from the projects of Kozikaza members.... Let yourself be inspired!` as string,
    'meta.inspiration.photo-item-page.title': $localize`:@@meta.inspiration.photo-item-page.title:${params.alt}:alt: | Kozikaza` as string,
    'meta.inspiration.hd-item-page.title': $localize`:@@meta.inspiration.hd-item-page.title:${params.title}:title: | Kozikaza` as string,
    'meta.landing.vert-baudet.title': $localize`:@@meta.landing.vert-baudet.title:Designing a child's bedroom: tips to gain space, encourage autonomy | Kozikaza` as string,
    'meta.landing.vert-baudet.description': $localize`:@@meta.landing.vert-baudet.description:Create a practical yet warm environment, a children's room in which you want to spend time, which leaves room for creativity. The experts at Vert Baudet share their advice on how to design a child's bedroom.` as string,
    // PRO DECO OFFERS
    'kz-breadcrumb.pro.listing-deco-offers': $localize`:@@kz-breadcrumb.pro.listingDecoOffers:Decoration services` as string,
    'kz-breadcrumb.pro.listing-serviceType': $localize`:@@kz-breadcrumb.pro.listing-serviceType:Type ${params.serviceTypeName}:serviceTypeName:` as string,
    'meta.pro.listing-deco-offers.title': $localize`:@@meta.pro.listing-deco-offers.title:Deco offers from the best interior designers | Kozikaza` as string,
    'meta.pro.listing-deco-offers.description': $localize`:@@meta.pro.listing-deco-offers.description:Discover the best interior designers to restyl your home with online or on-site coaching` as string,
    'meta.pro.listing-deco-offers-serviceType.title': $localize`:@@meta.pro.listingDecoOffers-serviceType.title:Deco offers "${params.serviceTypeName}:serviceTypeName:" from the best interior designers | Kozikaza` as string,
    'meta.pro.listing-deco-offers-serviceType.description': $localize`:@@meta.pro.listingDecoOffers-serviceType.description:Deco offers from the best interior designers "${params.serviceTypeName}:serviceTypeName:" to restyl your home with online or on-site coaching` as string,
    'meta.pro.listing-deco-offers-box.title': $localize`:@@meta.pro.listingDecoOffersBox.title:Deco offers from the best interior designers participating in the Coaching decoration box | Kozikaza` as string,
    'meta.pro.listing-deco-offers-box.description': $localize`:@@meta.pro.listingDecoOffersBox.description:Meet our interior decorators available throughout France and take advantage of your gift voucher included in the "decoration coaching" box` as string,
    'meta.pro.listing-deco-offers-box-serviceType.title': $localize`:@@meta.pro.listingDecoOffersBox-serviceType.title:Deco offers from the best interior designers "${params.serviceTypeName}:serviceTypeName:" participating in the Coaching decoration box | Kozikaza` as string,
    'meta.pro.listing-deco-offers-box-serviceType.description': $localize`:@@meta.pro.listingDecoOffersBox-serviceType.description:Meet our interior decorators "${params.serviceTypeName}:serviceTypeName:" available throughout France and take advantage of your gift voucher included in the "decoration coaching" box` as string,
    // PRO DECO TUTORIAL
    'kz-breadcrumb.pro.coachingTutorial': $localize`:@@kz-breadcrumb.pro.coachingTutorial:Interior designer platform` as string,
    'meta.landing.coachingTutorial.title': $localize`:@@meta.landing.coachingTutorial.title:Interior designer platform on Kozikaza, how does it work? | Kozikaza` as string,
    'meta.landing.coachingTutorial.description': $localize`:@@meta.landing.coachingTutorial.description:Choose an interior designer or interior architect on Kozikaza, enjoy simplified exchanges thanks to instant messaging and secure online payment.` as string,
    // PRO DECO TUTORIAL
    'kz-breadcrumb.pro.find-a-professional': $localize`:@@kz-breadcrumb.pro.find-a-professional:Find a professional` as string,
    'meta.pro.landing-find-a-professional.title': $localize`:@@meta.pro.landing-find-a-professional.title:Kozikaza finds you an interior decorator! | Kozikaza` as string,
    'meta.pro.landing-find-a-professional.description': $localize`:@@meta.pro.landing-find-a-professional.description:Describe your needs and we will find a decorator or interior designer who meets your requirements. You will receive 2 to 3 quotes. ` as string,
    // KAZAPLAN
    'meta.kazaplan.title': $localize`:@@meta.kazaplan.title:Free 3D Home Design Software - Floor Plan Creator` as string,
    'meta.kazaplan.description': $localize`:@@meta.kazaplan.description:Create your 3D Home Plan with ease with our Kazaplan Interior Design Software to draw, decorate and furnish your home. ✓100% online` as string,
    'meta.kazaplan.title-edit': $localize`:@@meta.kazaplan.title-edit:Edit 3D Plan ${params.planName}:planName: | Kozikaza` as string,
    'meta.kazaplan.title-view': $localize`:@@meta.kazaplan.title-view:3D Plan ${params.planName}:planName: | Kozikaza` as string,
    'meta.kazaplan.kazaplan-main.title': $localize`:@@meta.kazaplan.kazaplan-main.title:3D Plan ${params.planName}:planName: | Kozikaza` as string,
    // CHAT
    'meta.chat.title': $localize`:@@meta.chat.title:Chat` as string,
    'meta.chat.new.title': $localize`:@@meta.chat.new.title:New chat` as string,
    'meta.chat.description': $localize`:@@meta.chat.description:Chat room` as string,

    'auth.construction-date.asSoonAsPossible': $localize`:@@auth.construction-date.asSoonAsPossible:As soon as Possible` as string,
    'auth.construction-date.lessThanThreeMonths': $localize`:@@auth.construction-date.lessThanThreeMonths:Less than 3 months` as string,
    'auth.construction-date.lessThanSixMonths': $localize`:@@auth.construction-date.lessThanSixMonths:Less than 6 months` as string,
    'auth.construction-date.lessThanTweleveMonths': $localize`:@@auth.construction-date.lessThanTweleveMonths:Less than 12 months` as string,
    'auth.construction-date.lessThanTwentyFourMonths': $localize`:@@auth.construction-date.lessThanTwentyFourMonths:Less than 20 months` as string,
    'auth.construction-date.lessThanThirtySixMonths': $localize`:@@auth.construction-date.lessThanThirtySixMonths:Less than 36 months` as string,
    'auth.relatif-project.soon': $localize`:@@auth.relatif-project.soon:Soon` as string,
    'auth.relatif-project.inProgress': $localize`:@@auth.relatif-project.inProgress:In progress` as string,
    'auth.relatif-project.completedInSixmonths': $localize`:@@auth.relatif-project.completedInSixmonths:Completed In Six months` as string,
    'auth.relatif-project.none': $localize`:@@auth.relatif-project.none:none` as string,

    //outdoor-design-plan
    'meta.inspiration.outdoor-design.plan-gallery.title': $localize`:@@meta.inspiration.outdoor-design.plan-gallery.title:3D Landscape Plans - Inspirations for Landscaping and Decoration | Kozikaza ` as string,
    'meta.inspiration.outdoor-design.plan-gallery.description': $localize`:@@meta.inspiration.outdoor-design.plan-gallery.description:▻▻ Discover our landscape design plans created by our architects (Terraces, pools, pergolas...) Get inspired ! ` as string,

  };

  return (!!translation[key]) ? translation[key] : '';
}
